import { Injectable } from '@angular/core'

import { CountInventoryDto, FindInventoryItemDto, InventoryItemDto, PageResultDto, UpdateInventoryItemDto, ValidateImportInventoryDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class InventoryService {
  constructor(
    private httpService: HttpService) {
  }

  async find(searchInventoryItemDto: FindInventoryItemDto) {
    return await this.httpService.get<PageResultDto<InventoryItemDto>>('/merchant/v1/inventory/items', searchInventoryItemDto)
  }

  async findLowStock(searchInventoryItemDto: FindInventoryItemDto) {
    return await this.httpService.get<PageResultDto<InventoryItemDto>>('/merchant/v1/inventory/low-stock-items', searchInventoryItemDto)
  }

  async update(updateInventoryItemDto: UpdateInventoryItemDto) {
    return await this.httpService.put<boolean>('/merchant/v1/inventory/items', updateInventoryItemDto)
  }

  async exportExcel(email: string, tenantId: string) {
    return await this.httpService.get<Boolean>('/merchant/v1/inventory/export', { tenantId, email })
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<Boolean>(`/merchant/v1/inventory/import`, formData, config)
  }

  async validate(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<ValidateImportInventoryDto>(`/merchant/v1/inventory/validate`, formData, config)
  }

  async count() {
    return await this.httpService.get<CountInventoryDto>('/merchant/v1/inventory/count')
  }
}
